<template>
  <div class="tw-bg-white tw-h-96 tw-items-center tw-flex tw-justify-center" style="max-height: 80vh;">
    <div class="tw-flex tw-justify-center tw-flex-col tw-gap-4">
      <div class="tw-flex tw-justify-center">
        <Loader class="main" customClass="big tw-flex tw-justify-center"/>
      </div>
      <div class="tw-text-xl tw-text-gray-500">Authenticating with <span class="tw-capitalize">{{type}}</span>...</div>
    </div>
  </div>
  <div style="display: none">
    <!-- <Button id="googleBtn" :data="btnGoogleAuth" class="btn_social" @click="signInGoogle"/> -->
    <!-- <Button :data="btnFacebookAuth" class="btn_social" @click="facebookComponentCount+=1"> -->
      <!--facebookComponentCount+=1-->
     <!-- <v-facebook-login id="fbAuthxxxx" :key="facebookComponentCount" ref="facebookAuthRef" :app-id="fbAppId"
                        @login="signInFacebook"/> -->

      <v-facebook-login id="fbAuth" :app-id="fbAppId"  @login="signInFacebook"></v-facebook-login>

    <!-- </Button> -->
  </div>
</template>

<script setup>
import Button from '@/components/form/Button'
import {ref} from "@vue/reactivity";
import {onMounted, computed} from "vue";
import {useRoute} from "vue-router";
// import googleAuth from 'vue3-google-auth'
import VFacebookLogin from 'vue-facebook-login-component-next'
import Loader from '@/components/item-wizard/components/elements/Loader.vue'

const facebookAuthRef = ref(null)
const fbAppId = ref(process.env.VUE_APP_FB_APP_ID)


const allowedSocialAuths = ['facebook', 'google']

// const gAuth = googleAuth.useGAuth()
const facebookComponentCount = ref(0) // bug with close window

const route = useRoute()
const callback = ref(null)

const btnGoogleAuth = {
  title: 'Google',
  type: 'primary',
  icon: 'fab fa-google',
}

const btnFacebookAuth = {
  title: 'Facebook',
  type: 'primary',
  icon: 'fab fa-facebook-f',
}

const type = computed(() => route.params.type)

onMounted(async () => {
  // let type = route.params.type


  let mainQuotingPortalUrl = process.env.VUE_APP_QUOTING_PORTAL_DOMAIN
  if(!mainQuotingPortalUrl) mainQuotingPortalUrl = "https://ss-p3-quoting.conceptangle.com.au" // For testing

  if (!allowedSocialAuths.includes(type.value) || `${location.protocol}//${location.host}` !== mainQuotingPortalUrl){

  setTimeout(() => {
    window.close();
  },1000)

  } else {
    setTimeout(() => {
      if (type.value === 'google') {
        signInGoogle()
      }
      else { 
        document.getElementById('fbAuth').click()
        callback.value = route.query.callback
      }
    }, 3000)
  }


})

async function signInGoogle() {
  // This sends the user to the Google login page
  // The user will be redirected back to the current page with the access token in the URL
  // If the user is already logged in, the access token will be available immediately
  // If the user is not logged in, the user will be prompted to log in


  // Get the hash part of the URL
  const hash = window.location.hash.substring(1); // Remove the `#`

  // Parse the hash to find the parameters
  const params = new URLSearchParams(hash);

  const accessToken = params.get('access_token');
  // The original callback url is stored in the state parameter, so we do not loose it when the user is redirected back from Google
  const state = params.get('state');

  // Decode the state to get the original URL
  let originaCallbacklUrl = decodeURIComponent(state);

  if (accessToken) {
    await redirect(accessToken, originaCallbacklUrl)
  } else {
    let mainQuotingPortalUrl = process.env.VUE_APP_QUOTING_PORTAL_DOMAIN
    if (!mainQuotingPortalUrl) mainQuotingPortalUrl = "https://ss-p3-quoting.conceptangle.com.au" // For testing
    const redirectUri = `${mainQuotingPortalUrl}/auth/google`
    const clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID
    window.location =`https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/drive.metadata.readonly&include_granted_scopes=true&response_type=token&state=${route.query.callback}&redirect_uri=${redirectUri}&client_id=${clientId}`
  }

  // const auth = await gAuth.signIn()
  // if (auth) {
  //   const access_token = await auth.getAuthResponse().access_token
  //   await redirect(access_token)
  // }
}

async function signInFacebook(data) {
  const access_token = data?.authResponse?.accessToken
  await redirect(access_token)
}

async function redirect(token, url) {
  window.location = `${url ?? callback.value}?token=${encodeURIComponent(`${token}`)}`
}

</script>
